// Main code for dev project in Bruno Simon's Threejs-journey course,
// pushed to'Illegal Operation' website front page

import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

const colours = [
    'AliceBlue','AntiqueWhite','Aqua','Aquamarine','Azure','Beige','Bisque','Black',
    'BlanchedAlmond','Blue','BlueViolet','Brown','BurlyWood','CadetBlue','Chartreuse',
    'Chocolate','Coral','CornflowerBlue','Cornsilk','Crimson','Cyan','DarkBlue','DarkCyan',
    'DarkGoldenRod','DarkGray','DarkGrey','DarkGreen','DarkKhaki','DarkMagenta',
    'DarkOliveGreen','DarkOrange','DarkOrchid','DarkRed','DarkSalmon','DarkSeaGreen',
    'DarkSlateBlue','DarkSlateGray','DarkSlateGrey','DarkTurquoise','DarkViolet','DeepPink',
    'DeepSkyBlue','DimGray','DimGrey','DodgerBlue','FireBrick','FloralWhite','ForestGreen',
    'Fuchsia','Gainsboro','GhostWhite','Gold','GoldenRod','Gray','Grey','Green','GreenYellow',
    'HoneyDew','HotPink','IndianRed','Indigo','Ivory','Khaki','Lavender','LavenderBlush','LawnGreen',
    'LemonChiffon','LightBlue','LightCoral','LightCyan','LightGoldenRodYellow','LightGray',
    'LightGrey','LightGreen','LightPink','LightSalmon','LightSeaGreen','LightSkyBlue',
    'LightSlateGray','LightSlateGrey','LightSteelBlue','LightYellow','Lime','LimeGreen','Linen',
    'Magenta','Maroon','MediumAquaMarine','MediumBlue','MediumOrchid','MediumPurple','MediumSeaGreen',
    'MediumSlateBlue','MediumSpringGreen','MediumTurquoise','MediumVioletRed','MidnightBlue','MintCream',
    'MistyRose','Moccasin','NavajoWhite','Navy','OldLace','Olive','OliveDrab','Orange','OrangeRed',
    'Orchid','PaleGoldenRod','PaleGreen','PaleTurquoise','PaleVioletRed','PapayaWhip','PeachPuff','Peru',
    'Pink','Plum','PowderBlue','Purple','RebeccaPurple','Red','RosyBrown','RoyalBlue','SaddleBrown',
    'Salmon','SandyBrown','SeaGreen','SeaShell','Sienna','Silver','SkyBlue','SlateBlue','SlateGray',
    'SlateGrey','Snow','SpringGreen','SteelBlue','Tan','Teal','Thistle','Tomato','Turquoise','Violet',
    'Wheat','White','WhiteSmoke','Yellow','YellowGreen'
];

const TWO_PI = 2.0 * Math.PI;
var rotation_done = false;
var far = 16.0;
function rand_int(m, n) {
    m = Math.ceil(m);
    n = Math.floor(n);
    return Math.floor(Math.random() * (n - m) + m);
}
const norm_random = _ => Math.random() - 0.5;

// Curves for orbits
const ellipse = (amp, freq, phase, t) => [
    Sizes.aspect * amp * Math.sin(freq * t - (phase)),
    amp * Math.cos(freq * t - (phase))
];
const hypocycloid = (a, b, amp, t) => {
    const r = a - b;
    const p = r / b;
    return [
        Sizes.aspect * amp * r * Math.cos(t) + b * Math.cos(p * t),
        amp * r * Math.sin(t) - b * Math.sin(p * t)
    ]
};

// Canvas
const canvas = document.querySelector('canvas.webgl');

// Scene
const scene = new THREE.Scene();

// Textures
const textureLoader = new THREE.TextureLoader();
const matcap_fo_texture = textureLoader.load('/textures/matcaps/mc_orange.png');
const matcap_text_texture = textureLoader.load('/textures/matcaps/2.png');

const cubeTextureLoader = new THREE.CubeTextureLoader();

const environmentMapTexture = cubeTextureLoader.load([
    '/textures/environmentMaps/frac00/px_s.png',
    '/textures/environmentMaps/frac00/nx_s.png',
    '/textures/environmentMaps/frac00/py_s.png',
    '/textures/environmentMaps/frac00/ny_s.png',
    '/textures/environmentMaps/frac00/pz_s.png',
    '/textures/environmentMaps/frac00/nz_s.png'
]);
const environmentMapTexture2 = cubeTextureLoader.load([
    '/textures/environmentMaps/frac01/px.png',
    '/textures/environmentMaps/frac01/nx.png',
    '/textures/environmentMaps/frac01/py.png',
    '/textures/environmentMaps/frac01/ny.png',
    '/textures/environmentMaps/frac01/pz.png',
    '/textures/environmentMaps/frac01/nz.png'
]);

const params = {
    colour: 0xffcc85,
    wireframe: false,
    wireframeLinewidth: 1,
    metalness: 1.0,
    roughness: 0.,
};

// Text / Fonts
const textMaterial = new THREE.MeshMatcapMaterial({matcap: matcap_text_texture});
const font_loader = new FontLoader();
const font_params = {
    font: null,
    size: 1.4,
    height: 0.02,
    curveSegments: 5,
    bevelEnabled: true,
    bevelThickness: 0.05,
    bevelSize: 0.03,
    bevelOffset: 0,
    bevelSegments: 4
};
let [text, textGeometry] = [null, null];
font_loader.load('/fonts/gentilis_bold.typeface.json', font => {
    font_params.font = font;
    const textGeometry = new TextGeometry('illegal operation', font_params);
    textGeometry.computeBoundingBox();
    textGeometry.center();
    text = new THREE.Mesh(textGeometry, textMaterial);
    text.scale.set(0.1, 0.1);
    text.position.z = -16.;
    scene.add(text);
});

// Sizes
class Sizes {
    static width = window.innerWidth;
    static height = window.innerHeight;
    static aspect = this.width / this.height;
    static update_renderer(renderer) {
        this.update_sizes();
        renderer.setSize(this.width, this.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }
    static update_camera(camera) {
        this.update_sizes();
        camera.aspect = this.width / this.height;
        camera.updateProjectionMatrix();
    }
    static update_sizes() {
        this.width = window.innerWidth;
        this.height = window.innerHeight;
    }
    static update(camera, renderer) {
        this.update_camera(camera);
        this.update_renderer(renderer);
    }
}

// Tori
class Torus {
    constructor(
        radius,
        tubeRadius,
        radialSegments=16,
        tubularSegments=100,
        extent=32,
        colour=0xffbb00
    ) {
        this.radius = radius;
        this.tubeRadius = tubeRadius;
        this.radialSegments = radialSegments;
        this.tubularSegments = tubularSegments;
        this.extent = extent;
        this.position = {
            x: this.rand_with_max_magnitude(this.extent),
            y: this.rand_with_max_magnitude(this.extent),
            z: -Math.random() * this.extent
        };
        this.velocity = {
            x: (Math.random() - 0.5) * 0.004,
            y: (Math.random() - 0.5) * 0.004,
            z: (Math.random() - 0.5) * 0.004
        };
        this.rotation = {
            x: this.rand_with_max_magnitude(Math.PI),
            y: this.rand_with_max_magnitude(Math.PI)
        };
        this.angular_velocity = {
            x: Math.random() * 2.,
            y: Math.random() * 2.
        };
        this.colour = colour;
    }

    rand_with_max_magnitude(n) {
        return (Math.random() - 0.5) * 2. * n;
    }

    get mesh() {
        const knotted = Math.random() > 0.25;
        let geometry;
        if (!knotted) {
            geometry = new THREE.TorusGeometry(
                this.radius, 
                this.tubeRadius, 
                this.radialSegments,
                this.tubularSegments
            );
        }
        else {
            const [p, q] = [rand_int(1,128), rand_int(1,128)];
            // const [p, q] = [8, 8];
            geometry = new THREE.TorusKnotGeometry(
                this.radius, 
                this.tubeRadius, 
                this.tubularSegments,
                this.radialSegments,
                p,
                q
            );
        }
        const material = new THREE.MeshMatcapMaterial({
            matcap: matcap_fo_texture,
            color: this.colour
        });
        const m = new THREE.Mesh(geometry, material);
        m.position.set(this.position.x, this.position.y, this.position.z);
        m.rotation.x = this.rotation.x;
        m.rotation.y = this.rotation.y;
        m.wrapper = this;
        return m;       
    }
}

const tori = [];
for (let i=0; i < 256; i++) {
    const t = new Torus(0.4 + norm_random() / 10., 0.05 + norm_random() / 10., 20, 256, 4, colours[rand_int(0, colours.length)]);
    const m = t.mesh;
    tori.push(m);
    scene.add(m);
}

const material = new THREE.MeshStandardMaterial({
    wireframe: params.wireframe,
    wireframeLinewidth: params.wireframeLinewidth,
    metalness: params.metalness,
    roughness: params.roughness
});
material.color = new THREE.Color(params.colour);

const material2 = material.clone();
material.envMap = environmentMapTexture;
material2.envMap = environmentMapTexture2;

// Platonic solids floating around
const icosahedron = new THREE.Mesh(
    new THREE.IcosahedronGeometry(1.0, 0),
    material
);
icosahedron.geometry.setAttribute('uv2', new THREE.BufferAttribute(icosahedron.geometry.attributes.uv.array, 2));
scene.add(icosahedron);

const tetrahedron = new THREE.Mesh(
    new THREE.TetrahedronGeometry(0.5, 0),
    material2
);
tetrahedron.geometry.setAttribute('uv2', new THREE.BufferAttribute(tetrahedron.geometry.attributes.uv.array, 2));
const tetrahedron2 = tetrahedron.clone();

scene.add(tetrahedron, tetrahedron2);
// ================================================================
// const octahedron = new THREE.Mesh(
//     new THREE.OctahedronGeometry(0.5, 0),
//     material2
// );
// octahedron.geometry.setAttribute('uv2', new THREE.BufferAttribute(octahedron.geometry.attributes.uv.array, 2));
// const octahedron_count = 8;
// const octahedrons = Array(octahedron_count).fill(0).map(el => octahedron.clone());
// octahedrons.forEach(o => {
//     o.geometry.setAttribute('uv2', new THREE.BufferAttribute(o.geometry.attributes.uv.array, 2));
//     o.position.x = rand_with_max_magnitude(8.0);
//     o.position.y = Sizes.aspect * rand_with_max_magnitude(8.0);
//     o.position.z = 6 - Math.random() * 2.;
// });
// scene.add(octahedron, ...octahedrons);

// ================================================================

// Lights
const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
const pointLight = new THREE.PointLight(0xffffff, 0.5);
pointLight.position.x = 2;
pointLight.position.y = 3;
pointLight.position.z = 4;

scene.add(ambientLight, pointLight);

window.addEventListener('resize', _ => {
    // Update sizes, camera, renderer when window resized
    Sizes.update(camera, renderer);
});

// Camera
const camera = new THREE.PerspectiveCamera(75, Sizes.width / Sizes.height, 0.1, 100);
camera.position.x = 0;
camera.position.y = 0;
camera.position.z = 6;
scene.add(camera);

// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

//Renderer
const renderer = new THREE.WebGLRenderer({
    canvas: canvas
});
Sizes.update_renderer(renderer);

// Animate
const clock = new THREE.Clock();
function render() {
    const elapsedTime = clock.getElapsedTime();
    if (text && !rotation_done) {
        text.rotation.z += 0.06;
        if (text.position.z < 4.7) {
            text.position.z += 0.05;
        }
        else {
            const skew = 1.;   // degrees??!   I don't get this, but have it working; it stops due to position!
            if (text.rotation.z % TWO_PI < skew) {
                rotation_done = true;

            }
        }
    }
    else if (text && rotation_done) {
        text.rotation.z += Math.sin(elapsedTime) / 512.;
    }
    
    tori.forEach(t => {
        t.rotation.x = t.wrapper.angular_velocity.x * elapsedTime * 0.2;
        t.rotation.y = t.wrapper.angular_velocity.y * elapsedTime * 0.3;

        t.position.x = t.position.x + t.wrapper.velocity.x;
        if (t.position.x >= far) {
            t.position.x -= 32.0;
        }
        if (t.position.x <= -far) {
            t.position.x += 32.0;
        }
        t.position.y = t.position.y + t.wrapper.velocity.y;
        if (t.position.y >= far) {
            t.position.y -= 32.0;
        }
        if (t.position.y <= -far) {
            t.position.y += 32.0;
        }
    });
    icosahedron.rotation.x = 0.3 * elapsedTime;
    icosahedron.rotation.y = 0.075 * elapsedTime;
    tetrahedron.rotation.x = 0.6 * elapsedTime;
    tetrahedron.rotation.y = 0.1 * elapsedTime;
    tetrahedron2.rotation.x = -1.2 * elapsedTime;
    tetrahedron2.rotation.y = -0.3 * elapsedTime;

    icosahedron.position.set(
        ...hypocycloid(5, 3, 0.25, 0.5 * elapsedTime), 2. * Math.cos(0.001 * elapsedTime)
    );
    tetrahedron.position.set(
        ...hypocycloid(8, 3, 0.25, 0.1 * elapsedTime), 0.3 * Math.cos(0.002 * elapsedTime)
    );
    tetrahedron2.position.set(
        ...ellipse(
            2.4, 
            0.4, 
            Math.PI / 2. + 0.8, 
            elapsedTime / 4.
        ), 
        0.2 * Math.cos(0.001 * elapsedTime)
    );

    camera.position.x += Math.cos(elapsedTime) / 1024.;
    camera.position.z += Math.sin(elapsedTime) / 512.;
    camera.position.y = -0.5 + Math.sin(elapsedTime / 32.);
    controls.update();
    renderer.render(scene, camera);
    window.requestAnimationFrame(render);
}

render();